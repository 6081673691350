import analysisCard from "../ToolsCard.vue";
import Button from "@/components/button";
import Loader from "@/components/loader";
import VueMultiselect from "@/components/vue-multiselect";
import axios from "../../../../axios/osint.js";
import toolsHelper from "@/mixins/toolsHelper";
import {mapActions, mapGetters, mapMutations} from "vuex";
import whatsappServices from "./services";
import {splitCountryCode} from "@/utils/functions";
import {zip} from "rxjs";
import OsintAxios from "@/axios/osint.js";
import neoAnalyse from "@/components/analyse";

export default {
    name: "neo-whatsapp",
    components: {
        "analysis-card": analysisCard,
        "neo-button": Button,
        "neo-loader": Loader,
        VueMultiselect,
        "neo-button": Button,
        neoAnalyse,
    },
    mixins: [toolsHelper],
    props: {
        toolData: Array,
        toolSearchQueries: Array,
    },
    data: function() {
        return {
            resultFound: false,
            showResult: false,
            countryCodes: [],
            allCountryCodes: null,
            searchedNumbers: [],
            imgUrl: null,
            request_id: null,
            tabName: null,
            phoneNumber: {
                number: null,
                countryCode: null,
            },
            loadingResult: false,
            about: null,
            customIcon: "whatsapp",
            allToolsNumbers: null,
            phone: null,
            businessDetails: null,
            status: null,
            businessInfo: null,
        };
    },
    computed: {
        ...mapGetters(["getAllToolsData"]),
        // bussinessHours() {
        //     let openHours = this.businessDetails.business_hours.business_config.map(el => {
        //         if(el.day_of_week.toLowerCase() == 'mon')
        //             return `${Math.floor(el.open_time/60)}:${el.open_time%60}`
        //     })
        //     console.log(openHours)
        // }
    },
    async mounted() {
        this.tabName = this.$route.name;
        await this.setCountryCodes();
        const dataRes = this.getToolsData(this.tabName, "whatsapp-tool");
        await this.getToolData();
        if (dataRes) {
            this.redirectToQuery(dataRes);
        } else {
            await this.redirectOnRefresh(this.$route.query?.query_id);
        }
    },
    beforeDestroy() {
        this.setToolsData("tools", "whatsapp-tool");
    },
    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA"]),
        ...mapActions(["getAllCasePhones"]),

        async searchNumber() {
            try {
                this.showResult = false;
                let countryCode = null;
                this.businessDetails = null;
                this.about = null;
                this.businessInfo = null;
                this.status = null;
                if (!this.validatePhone()) {
                    this.$toast.error("Please enter a valid phone number");
                    this.showResult = false;
                    return;
                }
                if (!this.phoneNumber.countryCode?.value) {
                    countryCode = await splitCountryCode(this.phoneNumber.number.value);
                    if (!countryCode.code) {
                        this.$toast.error("Please select country code");
                        this.showResult = false;
                        return;
                    }
                    let countryId = this.allCountryCodes.filter((res) => {
                        return "+" + res.country_phone_code == countryCode.code;
                    });
                    countryId = {...countryId};
                    this.phoneNumber.countryCode = {
                        res: countryId[0],
                        value: countryId[0].country_with_code,
                    };
                    this.phoneNumber.number.value = countryCode.number;
                }
                this.loadingResult = true;
                const result = await whatsappServices.searchNumber(this.phoneNumber.number.value, this.phoneNumber.countryCode.res._id);
                this.resultFound = result.data.image_available ? true : false;
                this.phone = result.data?.phone ? result.data?.phone : null;
                const query = {
                    query_id: result.data.query_id,
                };
                // this.about = result.data.status_available ? result.data.status.about : ""
                this.request_id = query.query_id;
                this.imgUrl = this.resultFound ? result.data.image_data : "";
                const searchedNumbers = this.searchedNumbers.map((res) => res.value);
                if (!searchedNumbers.includes(this.phoneNumber.number.value)) {
                    this.searchedNumbers.push({value: this.phoneNumber.number.value, visited: true});
                    const data = {
                        input_type: "",
                        name: this.phoneNumber.number.value,
                        value: this.phoneNumber.number.value,
                        platform: "whatsapp",
                        val_type: "phone",
                        visited: true,
                    };
                    this.allToolsNumbers.push(data);
                }
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "whatsapp-tool"});
                await this.setSearchedNumber();
                this.$router.push({path: this.$route.path, query}).catch((err) => {});
                this.loadingResult = false;
                // if (!result.data.status_available && !result.data.image_available) {
                //     this.$toast.error('No data found')
                //     this.loadingResult = false
                //     this.showResult = false
                //     return
                // }
                this.showResult = true;
            } catch (error) {
                console.log(error);
                if (error.response && error.response.status !== 406) this.$toast.error("Some error occured.");
                this.loadingResult = false;
                this.showResult = false;
            }
        },

        async setCountryCodes() {
            this.allCountryCodes = await whatsappServices.getAllCountryCodes();
            this.allCountryCodes.forEach((res) => {
                this.countryCodes.push({
                    res,
                    value: res.country_with_code,
                });
            });
        },
        async aboutStatus(phone) {
            let payload = {
                phone: phone,
            };
            let response = await OsintAxios.post("/whatsapp/whatsapp-status", payload, {headers: {"x-query-id": this.request_id}});
            this.about = response?.data?.status?.about;
            this.status = response?.data?.status_available;
            // if(response?.data?.status_available === false) this.$tost.error("Status Not Available")
        },
        async bussinessInfo(phone) {
            let payload = {
                phone: phone,
            };
            let response = await OsintAxios.post("/whatsapp/business-info", payload, {headers: {"x-query-id": this.request_id}});
            this.businessDetails = response?.data?.business_info;
            if (response.data.info && response.data.info === "The phone doesn't correspond to a business acoount.") this.businessInfo = "The phone doesn't correspond to a business account.";
            // this.$toast.error("The phone doesn't correspond to a business account.")
            else if (response.data.info) this.$toast.error(response.data.info);
        },
        handleChange(event) {
            this.phoneNumber.number = {
                value: event,
            };
        },
        getCountryCodes() {
            return [
                {
                    group: "Country Codes",
                    categories: this.countryCodes,
                },
            ];
        },
        getSearchedNumbers() {
            return [
                {
                    group: "Numbers",
                    categories: this.searchedNumbers,
                },
            ];
        },
        async getToolData() {
            let numbers = await this.getDropdownData();
            let data = [];
            numbers.forEach((res) => {
                data.push({
                    value: res.value,
                    visited: res.visited,
                });
            });
            this.allToolsNumbers = numbers;
            this.searchedNumbers = data;
        },
        async getDropdownData() {
            let data = this.toolData.filter((user) => user.val_type === "phone");
            return data;
        },
        redirectToQuery(data) {
            const query = {
                query_id: data.request_id,
            };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
            this.phoneNumber.countryCode = {
                res: {
                    _id: data.phoneNumber.countryCode.res._id,
                },
                value: data.phoneNumber.countryCode.value,
            };
            this.phoneNumber.number = {value: data.phoneNumber.number.value};
            this.resultFound = data.resultFound;
            this.showResult = data.showResult;
            // this.about = data.about
            this.imgUrl = data.imgUrl;
        },
        async redirectOnRefresh(query_id) {
            if (query_id) {
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);
                this.phoneNumber.countryCode = {
                    res: {
                        _id: selected_query.query.id,
                    },
                    value: selected_query.query?.country_with_code,
                };
                this.phoneNumber.number = {value: selected_query.query.number};
                await this.searchNumber();
            }
        },
        async setSearchedNumber() {
            const data = {
                input_type: "",
                name: this.phoneNumber.number.value,
                value: this.phoneNumber.number.value,
                platform: "whatsapp",
                val_type: "phone",
                visited: true,
            };
            await whatsappServices.setProfileData(data, this.$route.params.id);
            this.$emit("updateToolData", "whatsapp", [...this.allToolsNumbers], data);
            this.$emit("handleChecked", true);
        },
        setSearch() {
            this.$refs["analysis-tool-input"].search = this.phoneNumber.number.value;
        },
        validatePhone() {
            let number = this.phoneNumber.number?.value;
            if (number) {
                number = number.replace(/[^0-9+]/g, "");
                return true;
            } else {
                return false;
            }
        },
    },
};
